function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{staticStyle:{"min-heigh":"1200px !important"},attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4","lg":"4"}},[_c('v-card',{attrs:{"elevation":"3","max-width":""}},[_c('v-card-text',[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.$store.state.globalMonth,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.$store.state, "globalMonth", $event)},"update:return-value":function($event){return _vm.$set(_vm.$store.state, "globalMonth", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Mostrar información del mes:","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formatDate),callback:function ($$v) {_vm.formatDate=$$v},expression:"formatDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFecha),callback:function ($$v) {_vm.modalFecha=$$v},expression:"modalFecha"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"es"},model:{value:(_vm.$store.state.globalMonth),callback:function ($$v) {_vm.$set(_vm.$store.state, "globalMonth", $$v)},expression:"$store.state.globalMonth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.modalFecha = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.$store.state.globalMonth), _vm.main()}}},[_vm._v(" Mostrar ")])],1)],1)],1)],1)],1)],1)],1),_vm._l((_vm.estados),function(ref,i){
var actionIcon = ref.actionIcon;
var actionText = ref.actionText;
var rest = objectWithoutProperties( ref, ["actionIcon", "actionText"] );
var attrs = rest;
return _c('v-col',{key:i,staticClass:"mt-4",attrs:{"cols":"12","md":"4","lg":"4"}},[_c('material-stat-card',_vm._b({scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},domProps:{"textContent":_vm._s(actionIcon)}}),_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(actionText)+" ")])]},proxy:true}],null,true)},'material-stat-card',attrs,false))],1)}),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"4","lg":"4"}},[_c('material-stat-card',_vm._b({scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-package-variant-closed')}}),_c('div',{staticClass:"text-truncate"},[_vm._v("Total de guías")])]},proxy:true}])},'material-stat-card',{
          color: 'primary',
          hexacolor: '#00cae3',
          icon: 'mdi-package-variant-closed',
          title: 'Total de guias',
          value: _vm.total_guias.toString(),
          db_label: 'Pendiente de asignación',
        },false))],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }