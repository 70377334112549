<template>
  <v-container id="dashboard-view" fluid tag="section">
    <v-row>
      <v-col cols="12" style="min-heigh: 1200px !important">
        <v-row>
          <v-col cols="4" md="4" lg="4">
            <!-- <GraficaDeBarras></GraficaDeBarras> -->
            <!-- /** inicio */ -->
            <v-card class="" elevation="3" max-width="">
              <v-card-text>
                <v-dialog
                  ref="dialog"
                  v-model="modalFecha"
                  :return-value.sync="$store.state.globalMonth"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDate"
                      label="Mostrar información del mes:"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <!-- /*** DASHBOARD SELECT FECHA DATERANGE MES */ -->
                  <v-date-picker
                    v-model="$store.state.globalMonth"
                    type="month"
                    locale="es"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="red" @click="modalFecha = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="green"
                      @click="
                        $refs.dialog.save($store.state.globalMonth), main()
                      "
                    >
                      Mostrar
                    </v-btn>
                  </v-date-picker>
                  <!-- /*** DASHBOARD SELECT FECHA DATERANGE MES */ -->
                </v-dialog>
              </v-card-text>
            </v-card>
            <!-- /** fin */ -->
          </v-col>
        </v-row>
      </v-col>
      <!-- TARJETAS - CARTAS - fichas INDICADORES DE PEDIDOS SEGUN FECHA -->
      <v-col
        class="mt-4"
        v-for="({ actionIcon, actionText, ...attrs }, i) in estados"
        :key="i"
        cols="12"
        md="4"
        lg="4"
      >
        <material-stat-card v-bind="attrs">
          <template #actions>
            <v-icon class="mr-2" small v-text="actionIcon" />
            <div class="text-truncate">
              {{ actionText }}
            </div>
          </template>
        </material-stat-card>
      </v-col>
      <v-col class="mt-4" cols="12" md="4" lg="4">
        <material-stat-card
          v-bind="{
            color: 'primary',
            hexacolor: '#00cae3',
            icon: 'mdi-package-variant-closed',
            title: 'Total de guias',
            value: total_guias.toString(),
            db_label: 'Pendiente de asignación',
          }"
        >
          <template #actions>
            <v-icon class="mr-2" small v-text="'mdi-package-variant-closed'" />
            <div class="text-truncate">Total de guías</div>
          </template>
        </material-stat-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Utilities
import { get } from 'vuex-pathify';
import Vue from 'vue';

import { mapActions } from 'vuex';
import GraficaDeBarras from './DashboardContent/GraficaDeBarras';
import GraficaDeLineas from './DashboardContent/GraficaDeLineas';
const lineSmooth = Vue.chartist.Interpolation.cardinal({
  tension: 0,
});

export default {
  name: 'DashboardView',
  components: {
    GraficaDeLineas,
    GraficaDeBarras,
  },
  data: () => ({
    total_guias: 0,

    /** monthPicker data begin */

    modalFecha: false,
    /** monthPicker data fin */
    tabs: 0,

    total_guias: 0,
  }),
  methods: {
    ...mapActions('DashboardResumenPorEstado', {
      findResumenPorEstado: 'find',
    }),
    fnCalcularTotalesPorEstado() {
      this.findResumenPorEstado({
        query: {
          fecha: this.$store.state.globalMonth,
        },
      }).then((result) => {
        this.estados.forEach((element) => {
          element.value = '0';
        });
        console.log('Consultando información de totales por estado');
        this.total_guias = 0;
        result.forEach((result) => {
          console.log(result._id[0]);
          //search in array filter: devuelve el objeto
          //search in array findIndex: devuelve el index dentro del array
          let statusSpecs = this.estados.findIndex(
            (estado) => estado.db_label == result._id[0]
          );
          console.log(statusSpecs);
          /// agregar información del total a cada estado en el dashboard
          if (statusSpecs != -1) {
            this.estados[statusSpecs].value = String(result.total);
            this.total_guias += result.total;
          }
        });
      });
    },
    main() {
      this.fnCalcularTotalesPorEstado();
    },
  },
  mounted() {
    this.main();
    /** event listeners | GPSGUIAS */
    const { Gpsguias } = this.$FeathersVuex.api;
    Gpsguias.on('patched', (gpsGuia) => {
      this.fnCalcularTotalesPorEstado();
    });
    Gpsguias.on('created', (gpsGuia) => {
      this.fnCalcularTotalesPorEstado();
    });
    /** event listeners | GPSGUIAS */
  },
  computed: {
    /** */
    estados() {
      return this.$store.state.estados;
    },
    /** */
    formatDate() {
      return this.$store.state.globalMonth
        ? moment(this.$store.state.globalMonth).format('MMMM-YYYY')
        : '';
    },
    /** */
    sales: get('sales/sales'),
    totalSales() {
      return this.sales.reduce((acc, val) => acc + val.salesInM, 0);
    },
  },
};
</script>
